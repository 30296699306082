// public.blade.php

function formatearNumero(numero) {
    let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    return formatter.format(numero) /* $2,500.00 */
}

function quitarComasNumero(num) {
    let formateo = "0"
    if (num !== undefined) {
        num = `${num}`
        formateo = num.toString()
        formateo = formateo.replace(/,/gi, "")
    }
    return formateo
}

function convertirDecimaleFloat(num) {
    return typeof num === "undefined" ||
        num === null ||
        num === "" ||
        num === "." ||
        num.toString() === "NaN"
        ? 0
        : parseFloat(quitarComasNumero(num))
}

export function redondear(numero, decimales = 6) {
    numero = numero ? numero : 0
    numero = convertirDecimaleFloat(numero)
    return +`${Math.round(parseFloat(`${numero}e+${decimales}`))}e-${decimales}`
}

export function compararObjetos(data_inicial, data_final) {
    return JSON.stringify(data_inicial) !== JSON.stringify(data_final)
}

// Se usa en javascript HTML - PHP
// permite usar window al contrario del import
window.utils = {
    formatearNumero,
    redondear,
}
